.testConnectPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .statusMessage {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    transition: all 200ms ease-in-out;

    span {
      margin-left: 5px;
    }
  }

  .connectInfo {
    margin-top: 10px;
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
    min-height: 130px;
    max-height: 500px;
    padding-bottom: 10px;
    overflow: auto;
    overflow-x: hidden;

    .switches {
      padding-top: 0.5rem;
      > div:not(:first-child) {
        margin-top: 0.3rem;
      }

      .success {
        color: rgb(76, 175, 80);
      }

      .fail {
        color: #ff4040;
      }
    }

    .unitInfo {
      .unitName {
        font-size: 1.3rem;
        line-height: 1.3rem;
      }
      &:not(:first-child) {
        margin-top: 0.9rem;
      }
    }
  }
}
