.detail {
  padding: 20px 0;
  a {
    color: white;
    text-decoration: none;
  }

  .inputs {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;

    .input {
      margin-top: 20px;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    button:not(:last-child),
    a:not(:last-child),
    div:not(:last-child) {
      margin-right: 17px;
    }
  }

  .deleteButton {
    background: #eb5757;
  }

  .submitButton {
    background: #0300ae;
  }

  .loginButton {
    background: #f2994a !important;
  }

  .list {
    margin-top: 20px;

    .on {
      background-color: #ff0000;
    }

    .off {
      background-color: #06b10d;
    }

    .switchState {
      text-align: center;
      padding: 0.5rem 0;
      font-size: 0.8rem;
      line-height: 0.8rem;
      width: 65px;
    }
  }
}

.weekvalid {
  // opacity: .3;
  background-color: orange;
}

.weekInvalid {
  // opacity: .3;
  // opacity: .8;
  background-color: lightgray;
}

.week {
  text-align: center;
  vertical-align: center;
  padding: 0.3rem;
  display: inline-block;
  border-radius: 10%;
  font-weight: bold;

  // font-size: 1.1rem;
}

.weekContainer {
  &:not(:first-child) {
    margin-left: 0.3rem;
  }
}
