.primary {
    background-color: #3f51b5;

    &:hover {
        background-color: rgb(44, 56, 126);
    }

    &_disabled {
        background-color: #115293 !important;
    }
}

.info {
    background-color: #2196f3;

    &:hover {
        background-color: rgb(23, 105, 170);
    }

    &_disabled {
        background-color: #1976d2 !important;
    }

}

.secondary {
    background-color: #f50057;

    &:hover {
        background-color: rgb(171, 0, 60);
    }

    &_disabled {
        background-color: #9a0036 !important;
    }

}

.success {
    background-color: #4caf50;

    &:hover {
        background-color: rgb(53, 122, 56);
    }

    &_disabled {
        background-color: #388e3c !important;
    }

}

.warning {
    background-color: #ff9800;

    &:hover {
        background-color: rgb(178, 106, 0);
    }

    &_disabled {
        background-color: #f57c00 !important;
    }

}

.error {
    background-color: #f44336;

    &:hover {
        background-color: rgb(170, 46, 37);
    }

    &_disabled {
        background-color: rgba(128, 128, 128, 0.623) !important;
    }

}


.disabled {
    color: white !important;
}
