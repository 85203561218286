.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98vw;
  height: 100vh;

  .closeBackground {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .wraper {
    position: absolute;
    z-index: 2;
    min-width: 100px;
    min-height: 50px;

    &[class*='holder'] {
      background: white;
      box-shadow: 0 0 10px 0.1px rgba(128, 128, 128, 0.329);
      box-sizing: border-box;
      padding: 10px;
    }
  }
}
