.attachButton {
  background: #56ccf2 !important;
  color: white;
  margin-left: 10px;
}

.attachSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .select {
    width: 180px;
  }
  margin-bottom: 20px;
}
