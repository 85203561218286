.paging {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  li {
    list-style-type: none;
    a {
      border: 1px solid rgba(171, 171, 171, 0.256);
      color: black;
      padding: 5px 10px;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }

  .activeItem {
    a {
      background: rgba(171, 171, 171, 0.256);
      color: #39c;
    }
  }
}
