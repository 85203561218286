.toggler {
  margin: 0;

  > span:first-child {
    width: 40px;
    padding: 12px 0;
  }

  *[class*='MuiSwitch-switchBase'] {
    padding: 9px 0;

    &[class*='Mui-checked'] {
      color: #3f51b4;

      + *[class*='MuiSwitch-track'] {
        background-color: #9fa8da;
      }
    }
  }

  *[class*='MuiFormControlLabel-label'] {
    margin-right: 10px;
  }

  *[class*='MuiFormControlLabel-labelPlacementTop'] {
    align-items: flex-start;
  }

  > span:last-child {
    font-size: 14px;
    line-height: 14px;
    color: var(--label-color);
    font-weight: bold;
  }
}
