.container {
    display: flex;
    flex-direction: column;
    // width: 30vw;
}


.button_container {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
