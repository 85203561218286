.scheduleForm {
  padding: 20px 0 40px 0;

  .inputs {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
  }

  .input {
    margin-top: 20px;
    position: relative;
    z-index: 2;
  }

  .panelInput {
    z-index: 1;
  }

  .datetimeInput {
    z-index: 3;
  }

  .buttons {
    max-width: calc(100% - 120px);
    margin: 100px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 90px;
  }

  .submitButton {
    background-color: #27ae60 !important;
  }

  .showOptionsModuleButton {
    background: #56ccf2;
    color: white;
  }

  .listModuleOptions {
    position: relative;
    width: 100%;

    .options {
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid rgba(128, 128, 128, 0.514);
      background: white;
      box-shadow: 1px 1px 6px 1px rgba(128, 128, 128, 0.386);
      > div {
        font-size: 1.1rem;
        line-height: 1.1rem;
        padding: 10px;
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid black;
        }
      }
    }
  }

  .switch_pannel {
    margin-top: 20px;

    .switch_pannel_header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 2%;
    }

    .switch_pannel_delete_button {
      cursor: pointer;
    }
  }

  .errorMessage {
    color: #ff4040;
    font-size: 0.9rem;
    line-height: 0.9rem;
    margin-top: 10px;
    display: block;
  }
}
