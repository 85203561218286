$colWidthMb: 70.8px;
$colWidth: 110px;

.module {
  .left {
    padding: 0.9rem;
    background-color: #1a1e3c;
    color: white;

    .heading {
      border-bottom: 1px solid white;
      padding-bottom: 0.9rem;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .headingName {
        font-size: 0.9rem;
        line-height: 0.9rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 5px;
          cursor: pointer;
          font-size: 1rem;
        }
      }

      &.full {
        justify-content: center;
        .headingName {
          width: 100%;
          font-size: 1rem;
          line-height: 1rem;

          svg {
            margin-left: 10px;
          }
        }
      }
    }

    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
      font-size: 0.8rem;
      > div {
        width: $colWidth;
        color: black;
        text-align: center;
        padding: 2px 0;
        border-radius: 2px;

        &:not(:first-child) {
          margin-left: 5px;
        }

        &.labelA {
          background: #8b9dff;
        }
        &.labelB {
          background: #4ad1c7;
        }
        &.labelC {
          background: #ffaf52;
        }
        &.labelD {
          background: #add172;
        }
      }
    }

    .switches {
      display: flex;
      justify-content: center;

      .switchesWrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 15px;

        > div {
          width: calc(25% - 5px);
          margin-top: -10px;
        }

        .oneSwitch {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
        }
      }
    }

    .switch {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .name {
        font-size: 0.8rem;
        line-height: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;

        &.empty {
          visibility: hidden;
        }

        span {
          word-break: keep-all;
        }
      }

      .box {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18vw;
        height: 18vw;
        max-width: 80px;
        max-height: 80px;

        img {
          width: 100%;
          height: 100%;
        }

        &.switching {
          opacity: 0.9;
          filter: blur(2px);
          box-shadow: 0 0 6px 0.1px #fff inset;
        }

        &.shape {
          cursor: pointer;
          background: #e5e5e5;
          border-radius: 4px;

          .stopIcon {
            width: 20px;
            height: 8px;
            background: white;
          }
        }
      }

      &.clickable {
        svg {
          color: grey;
        }
        .box {
          .stopIcon {
            background: grey;
          }
        }
      }

      &.on {
        .box {
          box-shadow: 0 2px 5px 1px rgba(128, 128, 128, 0.625) inset;

          svg {
            color: orange;
          }

          .stopIcon {
            background: orange;
          }
        }
      }
    }

    .footer {
      font-size: 1rem;
      line-height: 1rem;
      border-bottom: 1px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.8rem;
      margin-top: 20px;
    }

    .staticSwitches {
      display: flex;
      justify-content: center;

      .staticSwitchesWrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 15px;

        > div {
          width: calc(25% - 5px);
          margin-top: -10px;
        }

        .oneSwitch {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
        }

        .staticSwitch {
          height: 25px;
          max-width: 100%;
          font-size: 0.6rem;
          color: black;
          padding: 0;

          &.shadow {
            box-shadow: 10px -5px 20px 1px rgba(255, 255, 255, 0.555) inset;
          }

          &.color4 {
            background: #8b9dff;
          }
          &.color3 {
            background: #4ad1c7;
          }
          &.color2 {
            background: #ffaf52;
          }
          &.color1 {
            background: #add172;
          }
        }
      }
    }
  }

  .right {
    .listHeading {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(128, 128, 128, 0.26);
      padding-bottom: 0.5rem;

      > div:first-child {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
          background: gray;
          display: inline-flex;
          align-items: center;
          border-radius: 100%;
          padding: 0.2rem;

          svg {
            color: white;
          }
        }
      }

      .title {
        flex: 1;
        font-size: 1rem;
        line-height: 1rem;
      }

      > div:last-child {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 2.1rem;
        }
      }
    }
  }
}

.blurInput {
  background: rgba(255, 255, 255, 0.899);
  border: none;
  height: unset;
  padding: 6px;
  max-width: 31vw;

  &:focus {
    outline: none;
  }
}

@media screen and (min-width: 521px) {
  .module {
    display: flex;

    > div {
      width: 50%;
    }

    .left {
      padding: 1.5rem;

      .label {
        > div {
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }

      .switches {
        .switchesWrap {
          width: #{$colWidth * 4 + 10px * 4};
          padding-top: 15px;
          margin-left: -10px;

          > div {
            width: $colWidth;
            margin-left: 10px;
            margin-top: -10px;
          }
        }
      }

      .staticSwitches {
        .staticSwitchesWrap {
          width: #{$colWidth * 4 + 10px * 4};
          margin-left: -10px;

          > div {
            width: $colWidth;
            margin-left: 10px;
          }

          .staticSwitch {
            width: 85px;
            font-size: 0.7rem;
          }
        }
      }
    }

    .right {
      padding-left: 1rem;

      .listHeading {
        > div:first-child {
          width: 100px;

          > span {
            padding: 0.2rem;

            svg {
              color: white;
            }
          }
        }

        > div:last-child {
          width: 150px;
        }
      }

      .listCamera {
        height: 500px;
        overflow-y: auto;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }
    }
  }
}

.header {
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.5vh;

  .headInfo {
    display: flex;
    align-items: center;
    .siteName {
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.4rem;

      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 521px) {
  .header {
    background-position-y: 96.5vh;
    height: 90px;
    background-image: url(../../../../../images/operation_background.png);
    background-size: cover;
  }
}
