.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 4px);
  height: 100vh;
  background: rgba(0, 0, 0, 0.404);

  svg {
    font-size: 4rem;
  }
}
