.checkboxes {
  width: 100%;
  .label {
    background: white;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.3rem 0.5rem;
    margin-top: -10px;
  }

  .checkes {
    display: flex;
    align-items: center;
    user-select: none;

    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    > div:not(:first-child) {
      margin-left: 10px;
    }

    .check {
      padding: 0;

      &.checked {
        color: rgba(247, 0, 0, 0.54);
      }

      + span {
        margin-left: 5px;
      }
    }
  }
}
