.unit {
  > * {
    + * {
      margin-top: 25px;
    }
  }

  .switches {
    padding: 0 15px;
  }

  .unitActions {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 0 0 10px 0;
      font-size: 13px;
      line-height: 13px;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }

    .onoffInfo {
      margin-top: -5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 50px;
        height: 50px;
      }
    }

    p:not(:first-child) {
      margin-top: 15px;
    }
  }
}
