.search {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(45, 44, 44, 0.63);
  input {
    min-width: calc(100% - 2.6rem);
    &:focus {
      outline: none;
    }
    font-size: 1rem;
    border: none;
    padding: 0.4rem;

    & + svg {
      opacity: 0.5;
    }
  }

  &.filled {
    input {
      & + svg {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  &.focused {
    border-bottom-color: #06b10d;
  }
}
