.b16ChannelSettings {
  padding-bottom: 10px;

  .heading {
    .title {
      font-weight: bold;
      font-size: 1rem;
    }

    .commonInfo {
      margin-top: 20px;

      padding: 0 1rem 1rem 1rem;

      .checkboxes {
        margin-top: -0.5rem;

        > div {
          display: flex;
          align-items: center;

          .errorMessage + label {
            margin-right: 0;
          }

          &:nth-child(2) {
            justify-content: space-between;
          }

          &:last-child {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .switchDetail {
    background: #1a1e3c;
    padding: 1.5rem 0;

    .notePanel {
      color: white;

      .panelTitle {
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
      }

      .notes {
        width: calc(100% - 20px);
        margin: 1rem auto 0 auto;
      }

      .note {
        user-select: none;
        border-bottom: 1px dashed whitesmoke;
        padding-bottom: 1.4rem;

        &:not(:first-child) {
          margin-top: 1.5rem;
        }

        span {
          font-size: 0.9rem;
        }

        .noteHeading {
          padding-top: 0.5rem;

          .switch {
            margin-left: 0px;
            height: 25px;
            margin-bottom: -0.6rem;
          }

          .whiteBox {
            background: white;
            width: 100%;
            height: 35px;
            color: black;
            text-align: center;
            line-height: 35px;
          }

          .shadowBox {
            background: white;
            width: 100%;
            height: 45px;
            color: black;
            text-align: center;
            line-height: 45px;
            box-shadow: -14px -10px 22px 1px rgba(126, 126, 126, 0.377) inset;
          }

          .allCheck {
            color: white;

            &.checked {
              color: #ff6a69;
            }
          }
        }

        .noteDes {
          margin-top: 1.2rem;
          line-height: 1.2rem;
        }
      }
    }

    .blankPanel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      .footer {
        display: flex;
        align-items: center;
        flex-direction: column;

        .unitGroupChanger {
          display: flex;
          align-items: center;
          user-select: none;

          button[disabled] {
            opacity: 0.7;
          }

          span {
            font-size: 15px;
            color: #fff;
          }
          > * {
            margin: 10px 5px;
          }
        }

        .testConnectButton {
          margin-bottom: 10px;
        }

        &.isMobile {
          width: calc(100% - 20px);
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
          .testConnectButton {
            width: 100%;
          }

          .backButton {
            width: calc(50% - 5px);
            &:nth-child(3) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .earthquakeSettings,
  .leakageCheck,
  .allButtonMode {
    color: white;
  }

  .leakageCheckOptions {
    margin-bottom: 9px;

    button {
      span {
        font-size: 0.8rem;
        line-height: 1rem;
      }

      + button {
        margin-left: 10px;
      }
    }
  }

  .description {
    color: white;
    padding-right: 10px;
    padding-bottom: 30px;

    &[data-state='warn'] {
      color: #ff4040;
    }
  }

  .testConnectButton {
    width: 160px;
    height: 45px;
    border-radius: 0;
  }

  .backButton {
    width: 160px;
    height: 45px;
    border-radius: 0;
  }

  .panelFooter {
    display: flex;
    justify-content: center;
  }
}

.errorMessage {
  color: red;
}

.addButton {
  background: #56ccf2;
  border-radius: 0;
}

.remarkDrawCloseButton {
  margin-top: 1%;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .b16ChannelSettings {
    .heading {
      .commonInfo {
        .inputs {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > div {
            width: calc(100% / 3 - 20px);
          }
        }
      }
    }

    .switchDetail {
      display: flex;
      justify-content: center;

      .notePanel {
        width: 18%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .notes {
          width: 160px;
        }
      }

      .blankPanel {
        width: 18%;
      }
    }
  }
}
