.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container_header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_item {
  padding-right: 1rem !important;
}

.unitGroupEnabled {
  display: flex;
  align-items: center;
  user-select: none;
  > * {
    margin: 10px 5px;
  }
}

.unitGroupChanger {
  display: flex;
  align-items: center;
  user-select: none;

  button[disabled] {
    opacity: 0.7;
  }

  span {
    font-size: 15px;
  }

  > * {
    margin: 10px 5px;
  }
}

.module_container {
  margin-top: 1rem;
  width: 500px;
  height: 300px;
  border: 1px solid #000;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.unit_wrapper {
  // border: 1px solid #000;
  height: 90%;
  width: 17%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.unit_container {
  border: 1px solid #000;
  height: 85%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
}

.unit_input {
  width: 100%;
}

.switch_wrapper {
  height: 16%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.switch_container {
  border: 1px solid #000;
  height: 50%;
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.switch_input {
  margin-top: 5%;
  display: block;
  height: 50%;
  width: 100%;
}

.switch {
  height: 100%;
  width: 20%;
}

.switch_on {
  background-color: greenyellow;
}

.switch_off {
  background-color: red;
}
