.header {
  height: 90px;
  background-image: url(../../../../../images/operation_background.png);
  background-size: cover;
  background-position-y: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .headInfo {
    display: flex;
    align-items: center;
    .siteName {
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.4rem;

      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}

.connectStatus {
  margin-left: 2rem;
  background: white;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  padding: 0.4rem 0.6rem;

  > span:first-child {
    margin-right: 0.4rem;
  }

  > span:last-child {
    margin-left: 0.4rem;
  }

  &.inline {
    padding: 0.4rem 0;
    margin-left: 0;
    background: unset;

    font-size: 0.9rem;
    line-height: 0.9rem;

    > span:first-child {
      margin-right: 4px;
    }

    > span:last-child {
      margin-left: 4px;
    }
  }
}

.topTool {
  display: flex;
  align-items: center;
  justify-content: center;

  .switch {
    > span:last-child {
      font-weight: bold;
    }
  }
}

.deviceInformation {
  margin-bottom: 10px;
  font-size: 16px;
  color: #d91e11;
  span {
    font-weight: 600;
    margin-right: 2px;
  }
}
.lastLengthDeviceInformation {
  font-size: 16px;
  color: #d91e11;
  span {
    font-weight: 600;
    margin-right: 2px;
  }
}
@media screen and (min-width: 521px) {
  .header {
    background-position-y: 71.5vh;
  }
}
