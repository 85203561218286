.root {
  --unit-col-size: 190px;
  --unit-gap: 0.5rem;
  --unit-qty: 4;

  display: flex;
  min-height: 890px;
  width: 100%;
  flex-direction: column-reverse; //for none desktop

  .tabSelectors {
    --w: 100%;
    --h: 50px;
    --f-direction: row;
    --p-top: 10px;
    --mg-bottom: 20px;
    --shadow-dl: none;

    flex-direction: var(--f-direction);
    width: var(--w);
    height: var(--h);
    padding-top: var(--p-top);
    margin-bottom: var(--mg-bottom);

    display: flex;

    position: relative;
    z-index: 1;

    // shadow
    &::before {
      display: var(--shadow-dl);
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 10px;
      height: calc(100% - 20px);
      transform: translateY(-50%);
      z-index: -2;
      box-shadow: -2px 0 12px 2px #00000074;
    }

    // shadow cover
    &::after {
      display: var(--shadow-dl);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #1a1e3c;
      z-index: -1;
    }
  }

  .tabSelector {
    --bd-left-size: 0px;
    --bd-bottom-size: 3px;
    --width: 25%;
    --height: 100%;

    --del-btn-top: 50%;
    --del-btn-left: 20px;

    width: var(--width);
    height: var(--height);
    max-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    svg[role='del-btn'] {
      position: absolute;
      top: var(--del-btn-top);
      left: var(--del-btn-left);
      color: red;
      transform: translate(-50%, -50%);

      pointer-events: none;
      display: none;
    }

    span[role='label'] {
      color: #39c;
      font-size: 15px;
    }

    span[aria-label='add-btn'] {
      color: #fff;
      font-size: 30px;
    }

    border-left: var(--bd-left-size) solid #00000000;
    border-bottom: var(--bd-bottom-size) solid #00000000;

    &[aria-selected='true'] {
      cursor: default;
      pointer-events: none;
      border-left: var(--bd-left-size) solid #6f79c3;
      border-bottom: var(--bd-bottom-size) solid #6f79c3;

      // only show when tab is selected
      &[aria-selected='true'] {
        svg[role='del-btn'] {
          display: block;
          cursor: pointer;
          pointer-events: all;
        }
      }
    }
  }

  .tabContainer {
    max-width: 100%;
    overflow: scroll;
    overflow-y: visible; // not scroll for vertical
  }

  .units {
    display: flex;
    justify-content: center;
    width: calc(
      var(--unit-col-size) * var(--unit-qty) + var(--unit-gap) * var(--unit-qty)
    );

    .unit:not([role='start-of-group']) {
      margin-left: var(--unit-gap);
    }
  }

  .unit {
    &[aria-hidden='true'] {
      display: none;
    }

    .switchBox:not(:first-child) {
      margin-top: var(--unit-gap);
    }
  }

  .switchBox {
    width: 190px;
    height: 210px;
    background: white;
    padding: 0.5rem 0.5rem 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .boxTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .boxTopRight {
      display: flex;
      flex-direction: column;
    }

    .visibleSwitch {
      margin-left: 0;
      span {
        font-size: 0.8rem;
      }

      width: 40px;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: flex-start;
      > span:first-child {
        padding-left: 0;
      }
    }

    .lockSwitch {
      margin-left: 0;
      margin-right: 0;

      span {
        font-size: 0.8rem;
        line-height: 0.6rem;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        transform: translateY(-1px);
      }
    }

    .switchName {
      width: 100%;
      margin-top: 5px;
      border: 1px solid rgba(77, 77, 77, 0.742);
      padding: 0.2rem 0.5rem;

      &:focus {
        outline: none;
      }
    }

    .shadowButton {
      cursor: pointer;
      margin-top: 10px;
      box-shadow: 2px 2px 7px 0.2px rgba(128, 128, 128, 0.308);

      > div {
        background: white;
        width: 100%;
        height: 35px;
        color: black;
        text-align: center;
        line-height: 50px;
        box-shadow: -14px -10px 22px 1px rgba(126, 126, 126, 0.377) inset;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          color: gray;
          font-size: 2rem;

          &.small {
            font-size: 4rem;
          }
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    flex: 1;
    width: 0;
    flex-direction: row;

    .tabSelectors {
      --w: 50px;
      --h: 100%;
      --f-direction: column;
      --p-top: 29.2px;
      --mg-bottom: 0;
      --shadow-dl: block;
    }

    .tabSelector {
      --width: 100%;
      --height: 100px;
      --bd-left-size: 3px;
      --bd-bottom-size: 0px;

      --del-btn-top: 20px;
      --del-btn-left: 50%;
    }
  }
}

.switchPopup {
  .title {
    font-size: 0.7rem;
    text-align: center;
  }

  .iconOptions {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .iconOption {
      margin-left: 0;
      margin-right: 0;
      cursor: pointer;
    }

    .optionHolder {
      background: white;
      box-shadow: 0 0 6px 0.1px rgba(147, 147, 147, 0.473);
      width: 30px;
      height: 30px;
      border-radius: 4px;

      > div {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: -8px -5px 10px 0.2px rgba(215, 215, 215, 0.596) inset;
      }

      svg {
        color: grey;
      }
    }
  }
}

.stopIcon {
  width: 20px;
  height: 8px;
  background: orange;
}
