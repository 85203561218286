.loginForm {
  width: calc(100% - 40px);
  padding: 20px;
  max-width: 350px;
  box-sizing: border-box;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 120px;
    }
  }

  .inputs {
    margin-top: 60px;
    .input {
      width: 100%;

      label {
        font-size: 0.8rem;
        color: black;
      }
    }

    > div {
      margin-top: 20px;
    }
  }

  .buttons {
    margin-top: 30px;
  }

  .submitButton {
    width: 100%;
  }

  .forgotPassword {
    margin-top: 30px;
    text-align: center;

    span {
      cursor: pointer;
      font-size: 0.6rem;
    }
  }
}
