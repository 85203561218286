.socketClientSwitch {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  .buttons {
    > div {
      display: flex;
      align-items: center;

      span {
        display: block;
        width: 40px;
      }

      textarea {
        resize: none;
      }

      textarea,
      input {
        width: 200px;
      }

      textarea,
      input,
      button {
        margin-left: 5px;
      }

      + div {
        margin-top: 10px;
      }
    }

    + div {
      margin-top: 30px;
    }
  }

  .switchDevice {
    border-radius: 4px;
    background-color: rgba(224, 224, 224, 0.689);
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    > * {
      + * {
        margin-top: 10px;
      }
    }

    > button {
      width: 100%;
      padding: 0;
    }

    .onoff {
      width: 100%;
      display: flex;
      justify-content: space-between;

      button {
        padding: 0;
        width: 45px;
        min-width: unset;
      }
    }
  }
}
