.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .closeBackground {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.404);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .wraper {
    position: relative;
    z-index: 2;
    width: calc(100vw - 40px);

    min-height: 200px;
    background: white;
    box-shadow: 0 0 10px 0.1px rgba(128, 128, 128, 0.329);
    box-sizing: border-box;
    padding: 10px;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-child {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: bold;
      flex: 1;
      text-align: center;
      padding-left: 0.9rem;
      font-size: 1.5rem;
    }

    .closeIcon {
      border: 1px solid rgba(128, 128, 128, 0.377);
      color: rgba(128, 128, 128, 0.377);
      font-size: 0.9rem;
      cursor: pointer;
    }
  }
}
