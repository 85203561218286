.dialogContent {
  padding: 0 2rem;
}

.message {
  font-size: 1.1rem;
  line-height: 1.4rem;
  min-width: 220px;
  max-width: 320px;
  text-align: center;
}

.buttons {
  min-width: max-content;
  width: 150px;
  display: flex;
  justify-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 0;

  > :first-child {
    margin-right: 20px;
  }

  .submitButton {
    background-color: #eb5757;
  }
}

.confirmText {
  margin-top: 10px;
}
