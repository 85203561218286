.container_bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  bottom: 1rem;
}

.container_pagination {
  display: flex;
  align-items: center;
  padding: 10px;
}
.pagination_button {
  width: 2em;
  height: 2em;
  img {
    width: 2em;
    height: 2em;
    font-weight: 800;
    fill: #000000;
  }
  &.disabled {
    cursor: not-allowed;

    img {
      fill: #5c5f62;
    }
  }
}

.goBack_btn {
  margin-bottom: 2px;
  margin-left: 20px;
}
