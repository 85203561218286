$swt-width: 190px + 10px;
$swt-height: 275px + 10px;

.switchBox {
  background-color: #fff;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  width: $swt-width;
  height: $swt-height;
  box-shadow: 0 0 6px 0.1px rgba(0, 0, 0, 0.328);
  padding: 10px;

  > * {
    + * {
      margin-top: 10px;
    }
  }

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div {
      margin-left: 15px;
      margin-top: -4px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .input {
    border: 1px solid #707070;
    border-radius: 4px;

    font-size: 13px;
    line-height: 13px;
    overflow: hidden;

    display: flex;

    span {
      display: block;
      background-color: #707070;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;
      width: 50px;
    }

    input {
      padding: 5px 10px;
      border: none;
      outline: none;
      flex: 1;
      min-width: 0;
    }
  }

  .avatarButton {
    cursor: pointer;
    margin-top: 10px;
    background: white;
    width: 100%;
    height: 35px;
    color: black;
    text-align: center;
    line-height: 50px;
    box-shadow: -14px -10px 22px 1px rgba(126, 126, 126, 0.377) inset;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: gray;
      font-size: 2rem;

      &.small {
        font-size: 4rem;
      }
    }
  }
}

.stopIcon {
  width: 20px;
  height: 8px;
  background: orange;
}

.avatarOptions {
  .title {
    font-size: 0.7rem;
    text-align: center;
  }

  .iconOptions {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .iconOption {
      margin-left: 0;
      margin-right: 0;
      cursor: pointer;
    }

    .optionHolder {
      background: white;
      box-shadow: 0 0 6px 0.1px rgba(147, 147, 147, 0.473);
      width: 30px;
      height: 30px;
      border-radius: 4px;

      > div {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: -8px -5px 10px 0.2px rgba(215, 215, 215, 0.596) inset;
      }

      svg {
        color: grey;
      }
    }
  }
}
