.input {
  width: 100%;
  position: relative;
  .label {
    z-index: 2;
    position: absolute;
    top: -0.8rem;
    left: 0.5rem;
    background: white;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.3rem 0.5rem;

    & + select {
      padding: 0.6rem 0.5rem;
    }
  }

  select {
    border: 1px solid black;
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5px;

    option:first-child {
      opacity: 0.5;
      text-align: center;
    }

    &:focus {
      outline: none;
    }
  }

  &.error {
    label {
      border-color: #ff4040;
    }
  }

  .errorMessage {
    color: #ff4040;
    font-size: 0.9rem;
    line-height: 0.9rem;
    margin-top: 10px;
    display: block;
  }
}
