.tabs {
  .titles {
    display: flex;
    align-items: center;
    user-select: none;

    > div {
      font-size: 1rem;
      line-height: 1rem;
      border-bottom: 2px solid transparent;
      padding-bottom: 0.3rem;
      cursor: pointer;
      display: flex;
      align-items: center;

      > *:not(:first-child) {
        margin-left: 5px;
      }

      &:not(:first-child) {
        margin-left: 20px;
      }

      &.active {
        border-bottom-color: #0061ba;
      }
    }
  }

  .content {
    margin-top: 10px;
    padding-bottom: 40px;
  }
}
