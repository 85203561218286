.moduleCreateForm {
  padding: 20px 0;

  .radioGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 30px;
  }

  .inputs {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
    position: relative;

    .input {
      margin-top: 20px;
    }

    .generateCodeButton {
      background: rgb(86, 204, 242);
      position: absolute;
      top: 0;
      right: -40%;
      color: #fff;
    }
  }

  .buttons {
    max-width: calc(100% - 120px);
    margin: 30px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .submitButton {
    background: #27ae60;
  }
}
