.b16ChannelSettings {
  padding-bottom: 10px;
  .heading {
    .title {
      font-weight: bold;
      font-size: 1rem;
    }

    .commonInfo {
      margin-top: 20px;

      padding: 0 1rem 1rem 1rem;

      .checkboxes {
        margin-top: -0.5rem;

        > div {
          display: flex;
          align-items: center;

          .errorMessage + label {
            margin-right: 0;
          }

          &:nth-child(2) {
            justify-content: space-between;
          }

          &:last-child {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .switchDetail {
    background: #1a1e3c;
    padding: 1.5rem 0;

    .notePanel {
      color: white;

      .panelTitle {
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
      }

      .notes {
        width: calc(100% - 20px);
        margin: 1rem auto 0 auto;
      }

      .note {
        user-select: none;
        border-bottom: 1px dashed whitesmoke;
        padding-bottom: 1.4rem;

        &:not(:first-child) {
          margin-top: 1.5rem;
        }

        span {
          font-size: 0.9rem;
        }

        .noteHeading {
          padding-top: 0.5rem;

          .switch {
            margin-left: 0px;
            height: 25px;
            margin-bottom: -0.6rem;
          }

          .whiteBox {
            background: white;
            width: 100%;
            height: 35px;
            color: black;
            text-align: center;
            line-height: 35px;
          }

          .shadowBox {
            background: white;
            width: 100%;
            height: 45px;
            color: black;
            text-align: center;
            line-height: 45px;
            box-shadow: -14px -10px 22px 1px rgba(126, 126, 126, 0.377) inset;
          }

          .allCheck {
            color: white;

            &.checked {
              color: #ff6a69;
            }
          }
        }

        .noteDes {
          margin-top: 1.2rem;
          line-height: 1.2rem;
        }
      }
    }

    .blankPanel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      .footer {
        display: flex;
        align-items: center;
        flex-direction: column;

        .testConnectButton {
          margin-bottom: 10px;
        }

        &.isMobile {
          width: calc(100% - 20px);
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
          .testConnectButton {
            width: 100%;
          }

          .backButton {
            width: calc(50% - 5px);
            &:nth-child(3) {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .switchPanel {
      .switches {
        display: flex;
        justify-content: center;

        .unit {
          &:not(:first-child) {
            margin-left: 0.5rem;
          }

          .switchBox {
            &:not(:first-child) {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }

  .earthquakeSettings,
  .leakageCheck,
  .allButtonMode {
    color: white;
  }

  .leakageCheckOptions {
    margin-bottom: 9px;

    button {
      span {
        font-size: 0.8rem;
        line-height: 1rem;
      }

      + button {
        margin-left: 10px;
      }
    }
  }

  .description {
    color: white;
    padding-right: 10px;
    padding-bottom: 30px;
  }

  .testConnectButton {
    width: 160px;
    height: 45px;
    border-radius: 0;
  }

  .backButton {
    width: 160px;
    height: 45px;
    border-radius: 0;
  }

  .panelFooter {
    display: flex;
    justify-content: center;
  }
}

.switchBox {
  width: 190px;
  height: 210px;
  background: white;
  padding: 0.5rem 0.5rem 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .boxTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .boxTopRight {
    display: flex;
    flex-direction: column;
  }

  .visibleSwitch {
    margin-left: 0;
    span {
      font-size: 0.8rem;
    }

    width: 40px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: flex-start;
    > span:first-child {
      padding-left: 0;
    }
  }

  .lockSwitch {
    margin-left: 0;
    margin-right: 0;

    span {
      font-size: 0.8rem;
      line-height: 0.6rem;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      transform: translateY(-1px);
    }
  }

  .switchName {
    width: 100%;
    margin-top: 5px;
    border: 1px solid rgba(77, 77, 77, 0.742);
    padding: 0.2rem 0.5rem;

    &:focus {
      outline: none;
    }
  }

  .shadowButton {
    cursor: pointer;
    margin-top: 10px;
    box-shadow: 2px 2px 7px 0.2px rgba(128, 128, 128, 0.308);

    > div {
      background: white;
      width: 100%;
      height: 35px;
      color: black;
      text-align: center;
      line-height: 50px;
      box-shadow: -14px -10px 22px 1px rgba(126, 126, 126, 0.377) inset;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: gray;
        font-size: 2rem;

        &.small {
          font-size: 4rem;
        }
      }
    }
  }
}

.switchPopup {
  .title {
    font-size: 0.7rem;
    text-align: center;
  }

  .iconOptions {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .iconOption {
      margin-left: 0;
      margin-right: 0;
      cursor: pointer;
    }

    .optionHolder {
      background: white;
      box-shadow: 0 0 6px 0.1px rgba(147, 147, 147, 0.473);
      width: 30px;
      height: 30px;
      border-radius: 4px;

      > div {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: -8px -5px 10px 0.2px rgba(215, 215, 215, 0.596) inset;
      }

      svg {
        color: grey;
      }
    }
  }
}

.stopIcon {
  width: 20px;
  height: 8px;
  background: orange;
}

.errorMessage {
  color: red;
}

.addButton {
  background: #56ccf2;
  border-radius: 0;
}

.remarkDrawCloseButton {
  margin-top: 1%;
  width: 100%;
}

@media screen and (min-width: 521px) {
  .b16ChannelSettings {
    .heading {
      .commonInfo {
        .inputs {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > div {
            width: calc(100% / 3 - 20px);
          }
        }
      }
    }

    .switchDetail {
      display: flex;
      justify-content: center;

      .notePanel {
        width: 18%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .notes {
          width: 160px;
        }
      }

      .blankPanel {
        width: 18%;
      }

      .switchPanel {
        flex: 1;
        min-width: 0;
        overflow-y: hidden;
        overflow-x: auto;
        padding: 0 10px;
        .switches {
          width: calc(190px * 4 + 0.5rem * 4);
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .b16ChannelSettings {
    .switchDetail {
      .switchPanel {
        width: 100%;
        overflow: auto;
        overflow-y: hidden;

        .switches {
          padding: 1rem 1.2rem;
          width: fit-content;
          width: intrinsic;
          width: -moz-max-content;
        }
      }
    }
  }
}
