.noSite {
  color: #ee0202;
}

.existNoSite {
  color: #ee0202;
  border: 1px solid #ee0202;
  width: 300px;
  padding: 15px 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.05rem;
  line-height: 1.05rem;
}
