.calendar {
  display: flex;
  align-items: center;

  .picker {
    display: flex;
    align-items: center;

    margin-bottom: 2px;

    > div {
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;

      input {
        padding-top: 0;
        padding-bottom: 0;
      }

      input::placeholder {
        font-size: 0.8rem;
      }

      input,
      svg {
        color: gray;
      }
    }

    .pickerIcon {
      margin-left: -20px;
      color: grey;

      font-size: 1.2rem;

      &.calendarIcon {
        margin-bottom: 1px;
      }

      &.clickable {
        cursor: pointer;
        position: relative;
        z-index: 22;
      }
    }
  }
}
