.b1ChannelSettings {
  padding-bottom: 10px;

  > .heading {
    .title {
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
    }

    padding-top: 20px;

    + div {
      margin-top: 10px;
    }

    .errors {
      font-size: 13px;
      line-height: 13px;
      color: #ff4040;
      margin-top: -5px;
    }

    .inputs {
      margin-top: 5px;

      @media screen and (min-width: 521px) {
        display: flex;
        align-items: center;

        .input {
          flex: 1;
        }

        > * {
          + * {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .sideBoard {
    width: 100%;
    padding: 10px;
    background-color: #353852;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
      width: 100%;
    }

    .sideConfig {
      > .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          font-size: 14px;
          line-height: 14px;
          color: #fff;
          font-weight: bold;
        }

        div {
          width: 40px;
          height: 2px;
          background-color: #fff;
        }

        + * {
          margin-top: 20px;
        }
      }
    }

    .sideBlock {
      h5 {
        font-size: 15px;
        line-height: 15px;
        font-weight: bold;
        color: #fff;
        margin: 0;

        display: flex;
        align-items: center;

        svg {
          font-size: 16px;
          line-height: 16px;
          color: #f66a69;

          margin-bottom: -1px;
        }

        span {
          margin-left: 5px;
        }

        + div,
        + input {
          margin-top: 10px;
        }
      }

      > label {
        + div,
        + input {
          margin-top: 10px;
        }
      }

      > p {
        margin: 15px 0 15px 0;
        font-size: 14px;
        line-height: 17px;
        color: #fff;
      }

      .divider {
        width: 100%;
        height: 2px;
        background-color: #4e516a;
        border-top: 1px solid #282b45;
      }

      + .sideBlock {
        margin-top: 15px;
      }

      input:not([type]),
      input[type='text'],
      input[type] {
        width: 100%;
        display: block;
        padding: 8px 4px;
        border: none;
        outline: none;

        + p {
          text-align: center;
          margin: 5px 0;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }

    .sideButtons {
      display: flex;
      justify-content: center;
      padding-top: 10px;

      > div {
        width: calc(50% - 5px);
      }
      button {
        width: 100%;
        padding: 10px 0;
      }
    }

    .listInPanel {
      background-color: #4b4c64;
      padding: 10px;
      border: 1px solid #292c46;
      border-radius: 4px;

      font-size: 14px;
      line-height: 14px;
      color: #fff;

      .heading {
        display: flex;
        padding: 4px 0;

        > div:first-child {
          width: 35%;
        }
        > div:last-child {
          width: 65%;
        }
        border-bottom: 1px solid #66677c;
      }

      .table {
        max-height: var(--max-height);
        overflow: auto;
        .row {
          display: flex;
          align-items: center;
          height: 40px;

          > div:first-child {
            width: 35%;
            padding-left: 10px;
          }
          > div:last-child {
            width: 65%;
          }

          &:nth-child(2n) {
            background-color: #6f7083;
            color: #ababad;
          }
        }
      }

      + div {
        margin-top: 25px;
      }
    }

    .cbGroups {
      background-color: #494c63;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      > * {
        + * {
          margin-left: 10px;
        }
      }
    }
  }

  .board {
    background-color: #282b45;
    overflow: hidden;

    .left {
      box-shadow: 4px 0 6px 1px rgba(0, 0, 0, 0.328);
    }

    .right {
      box-shadow: -4px 0 6px 1px rgba(0, 0, 0, 0.328);
    }

    .main {
      padding: 20px;
      width: 100%;

      .scollContainer {
        max-height: 1200px;
        overflow: auto;

        .switchesContainer {
          display: flex;

          .unit {
            + * {
              margin-left: 15px;
            }

            > * {
              + * {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 521px) {
    .board {
      width: 0;
      min-width: 100%;
      display: flex;
      .left {
        max-width: 200px;
      }
      .right {
        max-width: 300px;
      }
      .main {
        flex: 1 1 0;
        min-width: 0;
      }
    }
  }
}
