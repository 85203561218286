.camera {
  border-bottom: 1px solid rgba(128, 128, 128, 0.26);
  .title {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;

    > div:first-child {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .name {
      flex: 1;
      font-size: 0.9rem;
      line-height: 0.9rem;
    }

    > div:last-child {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      .cameraToggle {
        height: 23px;

        &.cameraToggleBackground {
          background: #ff6969;
          color: white;
        }
      }
    }
  }

  .cameraExpand {
    .heading {
      margin-top: 30px;

      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;

        .tabItem {
          font-size: 0.7rem;
          font-weight: bold;
          padding: 0 0.4rem;

          svg {
            font-size: 0.8rem;
          }
        }

        .camLink {
          text-decoration: none;
          color: #39c;
          font-size: 0.7rem;
          margin-left: 0.8rem;
          font-weight: bold;
        }
      }

      .picker {
        display: flex;
        align-items: center;
        > div {
          width: 100%;

          input {
            padding-bottom: 0;
          }

          input,
          svg {
            color: gray;
          }
        }

        .pickerIcon {
          margin-left: -20px;
          margin-bottom: -10px;
          color: grey;

          font-size: 1.2rem;

          &.clickable {
            cursor: pointer;
            position: relative;
            z-index: 22;
          }
        }
      }
    }

    .expandContent {
      margin-top: 20px;
      padding-bottom: 20px;
    }
  }

  .thumbnailBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .captureButton {
    border-radius: 0;
    background: #ff6969;
    color: white;
    width: 140px;
    height: 30px;
    font-size: 0.8rem;

    svg {
      font-size: 1rem;
      margin-right: 20px;
    }
  }
}

.capturePopup {
  padding: 0 20px;

  .captureTitle {
    font-size: 1.1rem;
    font-weight: bold;
    background: whitesmoke;
    padding: 5px 0;
    text-align: center;
  }

  .capture {
    margin-top: 10px;
    img {
      width: 100%;
    }
  }
}

.captureFooter {
  margin-top: 20px;
  padding-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    height: 30px;
    width: 140px;
    font-size: 0.9rem;
    margin-left: 20px;
  }
}

@media screen and (min-width: 521px) {
  .camera {
    .title {
      > div:first-child {
        width: 100px;
      }

      > div:last-child {
        width: 150px;
      }
    }

    .cameraExpand {
      .heading {
        display: flex;
        align-items: flex-end;
        .tabs {
          width: 65%;

          .tabItem {
            font-size: 0.8rem;
            padding: 0 0.5rem;

            svg {
              font-size: 0.9rem;
            }
          }

          .camLink {
            font-size: 0.8rem;
            margin-left: 1rem;
          }
        }

        .picker {
          width: 35%;
          margin-bottom: -0.45rem;
          display: flex;
          justify-content: flex-end;

          > div {
            width: 90%;
          }
        }
      }
    }
  }
}
