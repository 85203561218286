.powerInputs {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.178);
  background-color: #fff;
  margin-top: 10px;
  padding-bottom: 10px;

  display: flex;
  align-items: center;
  flex-direction: column;

  h4 {
    width: 100%;
    font-size: 14px;
    line-height: 14px;
    padding: 5px;
    background-color: #dcdcdc;
    font-weight: normal;
    padding: 10px;
    margin: 0;
    text-align: center;
  }

  .inputs {
    width: 100%;
    padding: 10px;

    > div {
      + div {
        margin-top: 10px;
      }
    }

    .error {
      font-size: 12px;
      line-height: 12px;
      color: #ff4040;
      text-align: center;
      display: block;
      margin-top: 5px;
    }

    .input {
      display: flex;
      align-items: center;

      span {
        font-size: 13px;
        line-height: 13px;
      }

      input {
        border: 1px solid #d3d3d3;
        border-radius: 4px;
        flex: 1;
        min-width: 0;
        outline: none;
        padding: 5px 10px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}
