.createForm {
  padding: 20px 0;

  .inputs {
    width: 100%;

    .input {
      margin-top: 20px;
    }
  }

  .buttons {
    max-width: calc(100% - 120px);
    margin: 30px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .submitButton {
    background: #27ae60;
  }

  .note {
    font-size: 0.75rem;
    line-height: 0.75rem;
  }

  .switch {
    margin-left: 0;
    margin-right: 0;
  }
}

.formWrap {
  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;

    .thumbnail {
      width: 120px;
      height: 120px;
      background: rgba(128, 128, 128, 0.568);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 6rem;
        color: white;
      }
    }
  }
}

@media screen and (min-width: 521px) {
  .formWrap {
    display: flex;
    justify-content: center;

    .avatar {
      width: 200px;
    }

    .inputs {
      width: 280px;
    }
  }
}
