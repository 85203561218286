.list {
  .heading {
    .headingTitle {
      font-size: 1.7rem;
      line-height: 1.7rem;
      font-weight: bold;
    }

    .headingRight {
      .search {
        margin-top: 20px;
      }

      .settingsButton {
        margin-top: 20px;
      }

      .addButton {
        margin-top: 20px;
        margin-left: 20px;
        background: #56ccf2;
      }
    }
  }

  .table {
    display: flex;
    flex-direction: column;

    &.spacing {
      margin-top: 80px;
    }
  }

  .paging {
    align-self: flex-end;
  }

  .columnsOptions {
    display: flex;
    align-items: center;
    user-select: none;

    > div {
      cursor: pointer;
    }

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  @media screen and (min-width: 769px) {
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .headingRight {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .search {
          margin-top: 0px;
          margin-right: 30px;
        }
        .settingsButton {
          margin-top: 0px;
        }

        .addButton {
          margin-left: 20px;
          margin-top: 0px;
        }
      }
    }
  }
}
