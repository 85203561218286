.checkbox {
  margin: 0;

  *[class*='MuiButtonBase-root'] {
    padding: 0;

    color: var(--color);

    &[class*='Mui-checked'] {
      color: #ff6a69;
    }
  }
}
