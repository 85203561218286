.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container_header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_item {
    padding-right: 1rem !important;
}


.module_container {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.unit_wrapper {
    // border: 1px solid #000;
    height: 90%;
    width: 17%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.unit_container {
    border: 1px solid #000;
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5%;
}

.switch_button_container {
    padding: 0;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.switch_button {
    width: 30%;
    font-size: .8rem !important;
    min-width: 30% !important;
    line-height: 1 !important;
    margin: .2rem !important;
    margin-bottom: 0;
}

.switch_button_unknows {
    width: 80%
}

.unit_input {
    width: 100%;
}

.switch_wrapper {
    height: 16%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.switch_container {
    border: 1px solid #000;
    height: 50%;
    width: 70%;
    display: flex;
    justify-content: space-between;
}

.switch_input {
    margin-top: 5%;
    display: block;
    height: 50%;
    width: 100%;
}

.switch {
    height: 100%;
    width: 20%;
}

.switch_on {
    background-color: greenyellow;
}

.switch_off {
    background-color: red;
}

.power_unit_state_loading_container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.module_switch_button_wrapper {
    width: 500px;
    margin: .3rem;
    display: flex;
    justify-content: flex-end;
}


.switch_button_wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.module_switch_button_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.module_switch_button {
    margin: 0 .3rem
}
