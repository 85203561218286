.targetPower {
  > * {
    + * {
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 521px) {
    display: flex;
    align-items: center;

    > * {
      + * {
        margin-top: 0px;
        margin-left: 20px;
      }
    }
  }

  .target {
    display: flex;
    align-items: flex-end;

    input {
      border-radius: 4px;
      border: none;
      outline: none;
      padding: 7px 5px;
      color: #5a7cb4;
    }

    > div {
      display: flex;
      border: 1px solid #5a7cb4;
      border-radius: 4px;

      span {
        display: block;
        background-color: #5a7cb4;
        padding: 7px 5px;
        font-size: 12px;
        line-height: 13px;
        color: #fff;
        white-space: nowrap;
      }

      + span {
        font-size: 13px;
        line-height: 13px;
        margin-left: 5px;
        display: block;
        padding-bottom: 5px;
      }
    }
  }

  .powerLevel {
    display: flex;
    align-items: flex-end;

    .powerAvatar {
      background-color: #414141;
      padding: 0 20px;
      display: flex;
      align-items: center;

      &[data-level='safe'] {
        svg,
        span {
          color: #28d934;
        }
      }
      &[data-level='warn'] {
        svg,
        span {
          color: #f3b204;
        }
      }
      &[data-level='danger'] {
        svg,
        span {
          color: #ed5403;
        }
      }

      span {
        font-size: 12px;
        line-height: 13px;
        margin-left: 5px;
      }
    }

    > div {
      display: flex;
      border: 1px solid #5a7cb4;
      border-radius: 4px;

      > span {
        display: block;
        background-color: #5a7cb4;
        padding: 7px 5px;
        font-size: 12px;
        line-height: 13px;
        color: #fff;
        white-space: nowrap;
      }

      + span {
        font-size: 13px;
        line-height: 13px;
        margin-left: 5px;
        display: block;
        padding-bottom: 5px;
      }
    }
  }
}
