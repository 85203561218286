.switcher {
  width: 85px;
  .avatar {
    border-radius: 4px;
    width: 85px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .lamp {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      z-index: 2;

      &[data-type='blue'] {
        background-color: #05aff0;
      }

      &[data-type='red'] {
        background-color: #ff0000;
      }
    }

    &[data-switching='true'] {
      opacity: 0.9;
      filter: blur(2px);
      box-shadow: 0 0 6px 0.1px #fff inset;
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
    }

    .amp {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 77%;
      height: 70%;
      background-color: rgba(0, 0, 0, 0.607);
      color: #fff;
      padding: 2px;
      border-radius: 4px;
      font-size: 13px;
      line-height: 13px;
    }
  }

  .bottomBar {
    margin-top: 5px;

    width: 85px;
    height: 5px;
    border-radius: 5px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      width: 7px;
      height: 100%;
      background: #848484;

      &[data-light='on'] {
        &[data-type='safe'] {
          background-color: #8ef219;
        }

        &[data-type='warn'] {
          background-color: #fcd400;
        }

        &[data-type='danger'] {
          background-color: #c73d00;
        }
      }
    }
  }

  p {
    text-align: center;
    margin: 5px 0 0 0;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
  }

  + .switcher {
    margin-top: 15px;
  }
}

.btnReset {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;

  & button {
    background-color: #c73d00;
    color: #fff;
    cursor: pointer;
    font-size: 8px;
    border: none;
    outline: none;
    padding: 6px;
  }
}

.noButton {
  height: 26px;
}
