.createForm {
  padding: 20px 0;

  .inputs {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;

    .input {
      margin-top: 20px;
    }

    .group {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        width: calc(50% - 5px);
      }
    }
  }

  .buttons {
    max-width: calc(100% - 120px);
    margin: 30px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .submitButton {
    background: #27ae60;
  }
}
