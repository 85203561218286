.detail {
  padding: 20px 0;
  position: relative;

  .inputs {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;

    .input {
      margin-top: 20px;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    button:not(:last-child),
    div {
      margin-right: 17px;
    }
  }

  .deleteButton {
    background: #eb5757;
  }

  .submitButton {
    background: #0300ae;
  }

  .loginButton {
    background: #f2994a;
  }

  .list {
    margin-top: 20px;
  }

  .modulesFilter {
    padding-bottom: 80px;
  }
}
