.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.container_header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_item {
    padding-right: 1rem !important;
}

.pid {
    margin-bottom: .5rem;
}

.module_container {
    // margin-top: 1rem;
    width: 100%;
    height: 100%;
    // width: 500px;
    // height: 350px;
    // border: 1px solid #000;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0.05)
}

.unit_wrapper {
    // border: 1px solid #000;
    height: 90%;
    width: 23%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}


.unit_container {
    // border: 1px solid #000;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 5%;

    // border: 1px solid rgba(0, 0, 0, 0.2);
    // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0);

    border-radius: 3%;
}

.unit_switch_button_container {
    // padding: .5rem;
    padding-bottom: 0;
    width: 100%;
}

.switch_button {
    width: 100%;
    min-width: 100% !important;
    font-size: .7rem !important;
    line-height: 1 !important;
    margin: .2rem 0 !important;
    margin-bottom: 0;
    padding: .5rem 0;
    // margin: .5rem !important;

    @media (max-width: 960px) {
        // min-width: 3rem !important;

        width: 100%;
        min-width: 100% !important;
        font-size: .4rem !important;
    }
}

.all_switch_button {
    width: inherit !important;
    min-width: inherit !important;
    width: 50% !important;
    margin: .2rem !important
}


.unit_input {
    width: 100%;
}

.switch_wrapper {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // @media (max-width: 960px) {
    flex: 1;
    // }

}

.switch_wrapper_fake {
    height: 7%;
}

.switch_container {
    border: 1px solid #000;
    height: 50%;
    width: 70%;
    display: flex;
    justify-content: space-between;
}

.switch_input {
    margin-top: 5%;
    display: block;
    height: 50%;
    width: 100%;
}

.switch {
    height: 100%;
    width: 20%;
}

.switch_on {
    background-color: greenyellow;
}

.switch_off {
    background-color: red;
}

.power_unit_state_loading_container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.module_switch_button_wrapper {
    width: 100%;
    margin: .3rem;
    display: flex;
    justify-content: flex-end;
    height: 100%;
}


.module_switch_button_container {
    display: flex;
    width: 40%;
}

.module_switch_button {
    margin: 0 .3rem
}


.full {
    width: 100%;
    height: 70%;
    padding: .1rem 0
}
