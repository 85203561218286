.table {
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
  padding-bottom: 80px;
  border-bottom: 1px solid #c0c0c0;

  table {
    border-collapse: collapse;
    min-width: 100%;
    border-spacing: 0;
    thead {
      td {
        font-weight: bold;
        border-top: 1px solid #c0c0c0;
        white-space: nowrap;
      }

      tr {
        border-bottom: 1px solid #c0c0c0;
      }
    }

    td {
      font-size: 1rem;
      line-height: 1rem;
      min-width: 60px;
      padding: 0.5rem 5px;
    }

    tr:not(:last-child) {
      border-bottom: 1px solid #c0c0c0;
    }
  }

  &.small {
    table {
      td {
        font-size: 0.9rem;
        line-height: 0.9rem;
      }
    }
  }

  .emptyMessage {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 80px 0;
    text-align: center;
  }

  .toolRow {
    display: flex;
    align-items: center;

    button {
      font-size: 0.6rem;
      padding: 2px 4px;
      white-space: nowrap;
    }

    > *:not(:first-child) {
      margin-left: 10px;
    }
  }
}
