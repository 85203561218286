$colWidthMb: 70.8px;
$colWidth: 110px;

/**
 * この画面のベースカラー
 */
$moduleBaseColour: #272728;

/**
 * グループごとの行カラー
 */
$powerUnitRowColour: #1e1e1e;

/**
 * 操作画面のグループタイトルカラー
 */
$labelColour: #838383;

.module {
  .left {
    padding: 15px;
    background-color: $moduleBaseColour;
    color: white;
    display: flex;
    flex-direction: column;

    .heading {
      width: 100%;
      border-bottom: 1px solid white;
      padding-bottom: 0.9rem;

      display: flex;
      align-items: center;
      justify-content: center;

      input {
        border: 1px solid rgba(128, 128, 128, 0.56);
        outline: none;
        font-size: 13px;
        line-height: 13px;
        padding: 5px 10px;
        background: rgba(255, 255, 255, 0.899);
        border: none;
        height: unset;
        max-width: 31vw;

        &:focus {
          outline: none;
        }
      }

      .headingTitle {
        cursor: pointer;
        font-size: 0.9rem;
        line-height: 0.9rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 5px;
          cursor: pointer;
          font-size: 1rem;
        }
      }
    }

    .board {
      width: 100%;
      flex: 1;
      overflow-x: auto;
      padding-top: 10px;

      .columns {
        display: flex;

        .column {
          padding: 15px 5px;
          border-radius: 4px;
          border: 1px solid rgba(248, 248, 255, 0.708);

          + .column {
            margin-left: 15px;
          }
        }
      }
    }
  }

  .right {
    .listHeading {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(128, 128, 128, 0.26);
      padding-bottom: 0.5rem;

      > div:first-child {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
          background: gray;
          display: inline-flex;
          align-items: center;
          border-radius: 100%;
          padding: 0.2rem;

          svg {
            color: white;
          }
        }
      }

      .title {
        flex: 1;
        font-size: 1rem;
        line-height: 1rem;
      }

      > div:last-child {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 2.1rem;
        }
      }
    }
  }
}

.topTool {
  display: flex;
  align-items: center;
  justify-content: center;

  .switch {
    > span:last-child {
      font-weight: bold;
    }
  }
}
.deviceInformation {
  position: absolute;
  padding: 20px 0;
  max-height: 115px;
  width: 100%;
  z-index: -1;
  overflow-y: auto;
  width: 35rem;
}
.deviceInformationContainer {
  font-size: 16px;
  line-height: 30px;
  color: #d91e11;
  display: flex;
  justify-content: space-between;
  span {
    flex: 1;
    font-weight: 600;
    margin-right: 2px;
  }
}

@media screen and (min-width: 521px) {
  .module {
    display: flex;

    > div {
      width: 50%;
    }

    .right {
      padding-left: 1rem;

      .listHeading {
        > div:first-child {
          width: 100px;

          > span {
            padding: 0.2rem;

            svg {
              color: white;
            }
          }
        }

        > div:last-child {
          width: 150px;
        }
      }

      .listCamera {
        height: 500px;
        overflow-y: auto;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }
    }
  }
}

.header {
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.5vh;

  .headInfo {
    display: flex;
    align-items: center;

    .siteName {
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.4rem;

      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 521px) {
  .header {
    background-position-y: 96.5vh;
    height: 90px;
    background-image: url(../../../../../images/operation_background.png);
    background-size: cover;
  }
}
