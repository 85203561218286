.password {
  width: 100%;

  .wrapper {
    display: flex;
    align-items: center;

    .prefix {
      margin-left: 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  label {
    border: 1px solid black;
    position: relative;
    display: inline-block;
    width: 100%;
  }

  &.error {
    label {
      border-color: #ff4040;
    }
  }

  .errorMessage {
    color: #ff4040;
    font-size: 0.9rem;
    line-height: 0.9rem;
    margin-top: 10px;
    display: block;
  }

  .label {
    position: absolute;
    top: -0.8rem;
    left: 0.5rem;
    background: white;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.3rem 0.5rem;
  }

  input {
    width: 100%;
    border: none;
    padding: 0.6rem 0.5rem;
    font-size: 0.9rem;
    line-height: 0.9rem;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
  }
}
