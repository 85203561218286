.drawer {
  position: fixed;
  right: -100%;
  top: 56px;
  width: 100%;
  box-shadow: -2px 0 12px 0.2px rgba(128, 128, 128, 0.536);
  transition: right 0.2s ease-in-out;
  z-index: 23;

  &.isOpen {
    right: 0;
  }

  .wrapper {
    padding: 20px;
    height: 100vh;
    overflow-y: auto;
    background: white;
  }

  .closeBackground {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: -1;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headingRight {
      display: flex;
      align-items: center;
      justify-self: flex-end;

      .closeButton {
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .drawer {
    right: -55%;
    top: 64px;
    width: 55%;
  }
}
