.header {
  height: 90px;
  background-image: url(../../../../../images/operation_background.png);
  background-size: cover;
  background-position-y: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .headInfo {
    display: flex;
    align-items: center;
    .siteName {
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.4rem;

      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 521px) {
  .header {
    background-position-y: 71.5vh;
  }
}
