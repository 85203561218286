.capturePopup {
  padding: 0 20px;

  .captureTitle {
    font-size: 1.1rem;
    font-weight: bold;
    background: whitesmoke;
    padding: 5px 0;
    text-align: center;
  }

  .capture {
    margin-top: 10px;
    img {
      width: 100%;
    }
  }
}

.captureFooter {
  margin-top: 20px;
  padding-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    height: 30px;
    width: 140px;
    font-size: 0.9rem;
    margin-left: 20px;
  }
}

.savedCaptureList {
  thead {
    background: #d3d3d35a;
  }

  .thumbnail {
    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}
