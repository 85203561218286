.on {
  background-color: #ff0000;
}

.off {
  background-color: #06b10d;
}

.switchState {
  text-align: center;
  padding: 0.5rem 0;
  font-size: 0.8rem;
  line-height: 0.8rem;
  width: 65px;
}

.weekContainer {
  &:not(:first-child) {
    margin-left: 0.3rem;
  }
}
