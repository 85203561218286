.exportPopup {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.178);
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .input {
    width: 100%;

    + * {
      margin-top: 10px;
    }

    select {
      width: 200px;
      border: 1px solid #d3d3d3;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      font-size: 12px;
      line-height: 12px;
      padding: 7px 0;
      outline: none;
    }
  }

  .error {
    font-size: 12px;
    line-height: 12px;
    color: #ff4040;
    text-align: center;
    display: block;
    margin-top: 5px;
  }
}
