.powerChart {
  padding: 10px;

  > * {
    + * {
      margin-top: 15px;
    }
  }

  > .heading {
    .right {
      margin-top: 20px;
    }

    .left {
      > * {
        + * {
          margin-top: 10px;
        }
      }

      .currentTime {
        font-weight: 600;
        color: #30439d;
        font-size: 10px;
      }

      a {
        text-decoration: none;
      }
    }

    @media screen and (min-width: 521px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right {
        margin-top: 0px;
      }

      .left {
        display: flex;
        align-items: center;

        > * {
          + * {
            margin-top: 0px;
            margin-left: 20px;
          }
        }
      }
    }
  }

  .note {
    font-size: 11px;
    line-height: 11px;
    color: #ff4040;
    margin-left: 5px !important;
  }

  .rangeDate {
    display: flex;
    align-items: center;
    background-color: #f7f4f4;
    border-radius: 4px;
    padding: 5px 10px;

    > * {
      + * {
        margin-left: 15px;
      }
    }

    .dates {
      display: flex;
      align-items: center;

      > div {
        width: 100px;
        margin: 0;

        > div {
          &::before,
          &::after {
            display: none !important;
            content: none !important;
          }
        }

        input {
          text-align: center;
          padding: 5px 0;
          font-size: 12px;
          line-height: 12px;
          background-image: linear-gradient(#fff, #d8d8d8);
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.178);
        }
      }

      span {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  .select {
    display: flex;
    border-radius: 4px;
    overflow: hidden;

    > span {
      display: block;
      background-color: #3f51b5;
      color: #fff;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 10px;
      white-space: nowrap;
    }

    select {
      width: 200px;
      border: 1px solid #d3d3d3;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      font-size: 12px;
      line-height: 12px;
      padding: 7px 0;
    }
  }

  .optionsButton {
    user-select: none;
    background-image: linear-gradient(#5a7cb4, #30439d);
    color: #fff;
    font-size: 13px;
    line-height: 13px;
    padding: 8px 20px;
    border-radius: 4px;
  }

  .chartContainer {
    background-color: #e7f0f2;
    box-shadow: 0 0 4px 0.1px rgba(0, 0, 0, 0.37) inset;
    padding: 10px;

    .chart {
      margin-top: 10px;
      overflow-y: unset;
      overflow-x: auto;

      > div {
        min-width: 1000px;
        min-height: 500px;
        position: relative;
      }

      .loader {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      canvas {
        background-color: #fff;
      }
    }

    .chartTop {
      padding-left: 20px;
      padding-right: 10px;

      > * {
        + * {
          margin-top: 20px;
        }
      }

      .right {
        display: flex;

        .label {
          display: flex;
          align-items: center;
          font-size: 10px;
          cursor: default;
        }

        .label div {
          margin-right: 20px;
          display: flex;
          align-items: center;
          line-height: 0;

          &:first-child {
            span {
              width: 30px;
              height: 1px;
              margin-right: 8px;
              border-top: 3px dashed #000;
            }
          }

          &:last-child {
            span {
              width: 30px;
              height: 15px;
              background-color: #ebe54e;
              margin-right: 8px;
              border: 1px solid #999;
            }
          }
        }
      }

      @media screen and (min-width: 521px) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > * {
          + * {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
