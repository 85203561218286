.userForm {
  padding: 20px 0;

  .inputs {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;

    .input {
      margin-top: 20px;
    }
  }

  .buttons {
    max-width: calc(100% - 120px);
    margin: 30px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .submitButton {
    background: #27ae60;
  }
}
