.input {
  width: 100%;

  .tooltip {
    position: absolute;
    right: 0.2rem;
    font-size: 1.1rem;
    color: rgb(18, 103, 250);
    font-weight: bold;
    cursor: pointer;
  }

  label {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  &.error {
    label {
      border-color: #ff4040;
    }
  }

  .errorMessage {
    color: #ff4040;
    font-size: 0.9rem;
    line-height: 0.9rem;
    margin-top: 10px;
    display: block;
  }

  .label {
    font-size: 0.75rem;
    line-height: 0.75rem;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    width: 99.9%;
    border: none;
    padding: 0.6rem 0.5rem;
    font-size: 0.9rem;
    line-height: 0.9rem;

    &:focus {
      outline: none;
      background: white;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  .prefixWrap {
    display: flex;
    align-items: center;

    .prefix {
      margin-left: 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.readOnly {
    input,
    input:focus {
      background: rgba(167, 166, 166, 0.146);
    }
  }

  &.label-float {
    label {
      border: 1px solid black;
    }

    .label {
      position: absolute;
      top: -0.8rem;
      left: 0.5rem;
      background: white;
      padding: 0.3rem 0.5rem;
    }

    .tooltip {
      top: -1.2rem;
    }
  }

  &.label-block {
    input {
      border: 1px solid black;
    }

    .tooltip {
      top: 0rem;
    }
  }
}
